import {get, put, post} from './api-request'

export async function getShopById(shopId: string): Promise<Shop> {
    return await (
        await get('/cashier/api/shop', {params: {shopId}})
    ).data
}

export async function createShop(shop: any): Promise<void> {
    await post('/cashier/api/shop', shop)
}

export async function updateShop(shop: any): Promise<void> {
    await put('/cashier/api/shop', shop)
}

export async function getOwnerShop(): Promise<Shop[]> {
    return await (
        await get('/cashier/api/shop/owner')
    ).data
}

export async function getUnionShops(): Promise<Shop[]> {
    return await (
        await get('/cashier/api/shop/union/list')
    ).data
}

export async function getNonOwnerShop(): Promise<Shop[]> {
    return await (
        await get('/cashier/api/shop/non-owner')
    ).data
}

export async function getShopRenewalInfo(): Promise<any> {
    return await (
        await get('/cashier/api/shop/renewalInfo')
    ).data
}

export async function getShopInfo(): Promise<Shop> {
    return await (
        await get('/cashier/api/shop/shop-info')
    ).data
}

// 更新店铺营业时间
export async function updateShopBeginTime(time: string): Promise<void> {
    await post('/cashier/api/shop/time/setting', {beginTime: time, endTime: time})
}

//从日报历史获取哪一天的营业时间
export async function getShopBusinessTime(dateStr:string): Promise<string> {
    return await (
        await get('/cashier/api/statistics/business_time',{params:{dateStr}})
    ).data
}