import axios, { AxiosError } from 'axios'
import type { AxiosResponse, AxiosRequestConfig } from 'axios'
import { ElMessage } from 'element-plus'
import { router } from '../router'

export type ResponseErrorHandler = (
  response: AxiosResponse<any, any>,
  config?: RequestConfig
) => Promise<AxiosResponse<any, any>>
export type RequestErrorHandler = (error: AxiosError, config?: RequestConfig) => Promise<any>

export interface RequestConfig<D = any> extends AxiosRequestConfig<D> {
  show400Message?: boolean
  show500Message?: boolean
  show403Message?: boolean
  show401Message?: boolean
}

export interface ResponseResult {
  code?: string | null
  message?: string | null
  data?: any
  status: number
}

export function getDefaultBoolean(defaultValue: boolean, value?: boolean): boolean {
  if (typeof value === 'boolean') {
    return value
  }
  return defaultValue
}

export async function post<D = any>(
  url: string,
  data?: D,
  config?: RequestConfig<D>
): Promise<AxiosResponse<any, any>> {
  try {
    return handleResponse(await axios.post(url, data, config), config)
  } catch (e: any) {
    return handleError(e, config)
  }
}

export async function put<D = any>(
  url: string,
  data?: D,
  config?: RequestConfig<D>
): Promise<AxiosResponse<any, any>> {
  try {
    return handleResponse(await axios.put(url, data, config), config)
  } catch (e: any) {
    return handleError(e, config)
  }
}

export async function get<D = any>(
  url: string,
  config?: RequestConfig<D>
): Promise<AxiosResponse<any, any>> {
  try {
    return handleResponse(await axios.get(url, config), config)
  } catch (e: any) {
    return handleError(e, config)
  }
}

export async function _delete<D = any>(
  url: string,
  config?: RequestConfig<D>
): Promise<AxiosResponse<any, any>> {
  try {
    return handleResponse(await axios.delete(url, config), config)
  } catch (e: any) {
    return handleError(e, config)
  }
}

function handleResponse(
  response: AxiosResponse<any, any>,
  config?: RequestConfig
): Promise<AxiosResponse<any, any>> {
  if (response.status >= 200 && response.status < 300) {
    if (response.data.status === 500) {
        ElMessage.error({
          message: response.data.message,
          grouping: true,
          showClose: true
        })
        return Promise.reject(response)
    }
    if(response.data.status === 401){
      if(getDefaultBoolean(true, config?.show401Message)){
          ElMessage.error({
            message: response.data.message,
            grouping: true,
            showClose: true
          })
          localStorage.clear();
          sessionStorage.clear();
          router.push({ name: 'login' })
          return Promise.reject(response)
      }
    }
    return Promise.resolve(response)
  }
  if (response.status === 403) {
    if (getDefaultBoolean(true, config?.show403Message)) {
      ElMessage.error({
        message: response.data.message,
        grouping: true,
        showClose: true
      })
    }
  } else if (response.status === 400) {
    if (getDefaultBoolean(true, config?.show400Message)) {
      ElMessage.error({
        message: response.data.message,
        grouping: true,
        showClose: true
      })
    }
  } else if (response.status === 500) {
    if (getDefaultBoolean(true, config?.show500Message)) {
      ElMessage.error({
        message: response.data.message,
        grouping: true,
        showClose: true
      })
    }
  } else if (response.status === 401) {
    if (getDefaultBoolean(true, config?.show401Message)) {
      ElMessage.error({
        message: '登录已失效',
        grouping: true,
        showClose: true
      })
    }
  }

  return Promise.reject(response)
}

function handleError(error: AxiosError, config?: RequestConfig): Promise<any> {
  if (typeof error.response === 'object' && error.response !== null) {
    return handleResponse(error.response, config)
  }
  if (getDefaultBoolean(true, config?.show500Message)) {
    ElMessage.error({
      message: '网络异常，请稍后重试',
      grouping: true,
      showClose: true
    })
  }

  return Promise.reject(error)
}
